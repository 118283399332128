<template>
  <div class="view-home">
    <p>Добро пожаловать на Legacy Sentry сервер для Counter Strike 1.6!</p>
    <p>На этом сервере ты можешь поиграть в один из лучших модов <span class="has-text-weight-bold">CSDM Setry + Laser Mine.</span></p>
    <p>Почему это "Legacy" и что особенного на этом сервере, можно почитать в разделе <RouterLink :to="{name: 'features'}">Особенности</RouterLink>.</p>
    <p>Для того, чтобы начать игру, запусти игру и введи в консоли (~) следующую команду</p>

    <div class="terminal">connect {{ $env.VUE_APP_SERVER_ADDR }}</div>

    <p>Рекомендую использовать официальный клиент CS 1.6 от Legacy Sentry. Это классическая, немодифицированная версия
      с уже скачанными картами и другими ресурсами.</p>

    <p><a href="https://disk.yandex.ru/d/X3mYAYYZClv5jw" class="downloadcs" title="Скачать установочный файл Counter Strike 1.6 Legacy Sentry">
      <img :src="csIcon" width="50" height="50" alt="">
      Скачать клиент Counter Strike 1.6 (~200 Мб)
    </a></p>

    <p>В игре, нажми <code>N</code> чтобы открыть меню игрока.</p>

    <p>Так же, необходимо ознакомиться с <RouterLink :to="{name: 'rules'}">Правилами сервера</RouterLink> и <RouterLink :to="{name: 'start'}">Информацией для новых игроков</RouterLink>.</p>

    <div class="mt-2">
      <b-carousel-list
          v-model="values"
          :data="slides"
          :arrow="true"
          :arrow-hover="false"
          :items-to-show="itemsToShow"
          :items-to-list="increment"
          :repeat="repeat"
          :has-drag="drag"
          :has-grayscale="gray"
          :has-opacity="opacity"
          :as-indicator="true"
          iconPrev="icon-left"
          iconNext="icon-right"
      >
        <template #item="list">
          <section class="carousel-section-item" @click="showFullImage(list.image, list.index)">
            <b-image
                :src="list.image"
                v-bind="list"
            />
          </section>
        </template>
      </b-carousel-list>
    </div>

    <b-modal v-model="isImageModalActive">
      <b-carousel
          animated="fade"
          :autoplay="false"
          :arrow-hover="false"
          icon-size="is-large"
          v-model="imageIndex"
          iconPrev="icon-left"
          iconNext="icon-right"
      >
        <b-carousel-item v-for="(carousel, i) in slides" :key="i">
          <b-image
              :src="carousel.image"
              v-bind="carousel"
          />
        </b-carousel-item>
      </b-carousel>

<!--      <p class="image is-4by3">-->
<!--        <img :src="imageModal">-->
<!--      </p>-->
    </b-modal>
  </div>
</template>
<script>
import CsIcon from '../assets/images/cs_icon.png';
import Screen1 from '../assets/images/screen_1.jpg';
import Screen2 from '../assets/images/screen_2.jpg';
import Screen3 from '../assets/images/screen_3.jpg';
import Screen4 from '../assets/images/screen_4.jpg';
import Screen5 from '../assets/images/screen_5.jpg';

export default {
  data() {
    return {
      values: 1,
      itemsToShow: 3,
      increment: 1,
      repeat: true,
      drag: true,
      gray: false,
      opacity: false,
      isImageModalActive: false,
      imageIndex: 0,
      csIcon: CsIcon,
      slides: [
        {
          image: Screen1,
        },
        {
          image: Screen2,
        },
        {
          image: Screen3,
        },
        {
          image: Screen4,
        },
        {
          image: Screen5,
        },
      ]
    };
  },

  methods: {
    showFullImage(url, index) {
      this.imageIndex = index;
      this.isImageModalActive = true;
    }
  },
};
</script>