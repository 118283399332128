<template>
  <SideWidget class="widget-current-map" side="right">
    <p class="map-name">{{ mapName }}</p>
    <img :src="mapPreviewUrl" :alt="mapName">
    <b-progress
        :rounded="false"
        :value="players"
        :max="maxPlayers"
        show-value
    >
      {{ players }}/{{ maxPlayers }}
    </b-progress>

    <b-input
        readonly="true"
        v-model="serverIp"
        @focus="selectAll"
    ></b-input>
    <p class="server-ip mb-1"></p>

    <button
        class="button is-primary is-small btn-block"
        :title="playersOnlineHint"
        @click.prevent="togglePlayersOnline"
        :disabled="players === 0"
        v-text="playersOnlineHint"
    />
    <div class="players-online" v-if="isShowPlayersOnline">
      <ul>
        <li v-for="player in playersOnline" :key="player.Id">
          {{ player.Name }} [{{ player.Frags }}] ({{ player.TimeF }})
        </li>
      </ul>
    </div>
  </SideWidget>
</template>

<script>
import SideWidget from './SideWidget.vue';
import axios from 'axios';

export default {
  components: {
    SideWidget,
  },

  data() {
    return {
      serverIp: this.$env.VUE_APP_SERVER_ADDR,
      mapName: '',
      maxPlayers: 0,
      players: 0,
      isShowPlayersOnline: false,
      playersOnline: [],
    };
  },

  computed: {
    mapPreviewUrl() {
      if (this.mapName) {
        return `/maps/${this.mapName}.jpg`;
      } else {
        return '/maps/none.jpg';
      }
    },

    playersOnlineHint() {
      if (this.players === 0) {
        return 'Сервер пустой.';
      }

      return this.isShowPlayersOnline ? 'Скрыть игроков онлайн' : 'Показать игроков онлайн'
    },
  },

  methods: {
    selectAll(event) {
      event.target.select();
    },

    getServerInfo() {
      axios.get('/api/map')
          .then(response => {
            this.mapName = response.data.map;
            this.players = response.data.players;
            this.maxPlayers = response.data.maxPlayers;
          });
    },

    getPlayersOnline() {
      axios.get('/api/online')
          .then(response => {
            this.playersOnline = response.data;
          });
    },

    togglePlayersOnline() {
      this.isShowPlayersOnline = ! this.isShowPlayersOnline;
      if (this.isShowPlayersOnline) {
        this.getPlayersOnline();
      }
    }
  },

  mounted() {
    this.getServerInfo();
  },
};
</script>

<style>
  .widget-current-map {
    font-weight: bold;
  }
  .map-name {
    margin-bottom: 5px;
    text-align: center;
  }

  .progress-wrapper:not(:last-child) {
    margin-bottom: 5px;
  }
</style>