<template>
  <SideWidget side="left" class="no-padding">
    <menu class="main-menu">
      <Transition name="slide">
        <ul :class="{'menu-list': true, 'is-open': isOpen}">
          <li><RouterLink to="/">Главная</RouterLink></li>
          <li><RouterLink :to="{name: 'bans'}" @click="isOpen = false">Банлист</RouterLink></li>
          <li><RouterLink :to="{name: 'stats'}">Статистика</RouterLink></li>
          <li><RouterLink :to="{name: 'start'}">Информация</RouterLink></li>
          <li><RouterLink :to="{name: 'features'}">Особенности</RouterLink></li>
          <li><RouterLink :to="{name: 'rules'}">Правила</RouterLink></li>
          <li><RouterLink :to="{name: 'contacts'}">Контакты</RouterLink></li>
        </ul>
      </Transition>

      <button class="btn-burger" @click.prevent="toggleMenu" title="">
        <div class="icon-menu"/>
      </button>
    </menu>
  </SideWidget>
</template>

<script>
import SideWidget from "@/components/SideWidget.vue";

export default {
  components: {
    SideWidget,
  },

  data() {
    return {
      isMainMenu: true,
      isOpen: false,
    };
  },

  computed: {
    classList() {
      return {
        'main-menu': this.isMainMenu,
        'is-open': this.isOpen,
      };
    }
  },

  methods: {
    toggleMenu() {
      this.isOpen = ! this.isOpen;
    }
  }
};
</script>

<style lang="scss">
@use "bulma/sass/utilities/mixins";
@import "@/assets/scss/_shared";

.main-menu {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  padding: 0;
  margin: 0;
  user-select: none;

  .router-link-exact-active, a:hover {
    background-color: #C4B550;
    color: darken($bg-color, 20%);
  }

  a {
    color: white;
    display: block;
    padding: 5px;
    text-decoration: none;
  }

  .btn-burger {
    @include bordered;
    margin: 2px;
    background: darken($bg-color, 20%);
    color: white;
  }

  .menu-list {
    display: none;

    &.is-open {
      display: block;
    }
  }

  @include mixins.tablet {
    .btn-burger {
      display: none;
    }
    .menu-list {
      display: block;
    }
  }

  .v-enter-active {
    transition: all 0.3s ease-out;
  }
  .v-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
}
</style>