import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/bans',
    name: 'bans',
    component: () => import('../views/BanListView.vue'),
  },
  {
    path: '/stats',
    name: 'stats',
    component: () => import('../views/StatisticsView.vue'),
  },
  {
    path: '/start',
    name: 'start',
    component: () => import('../views/InformationView.vue'),
  },
  {
    path: '/features',
    name: 'features',
    component: () => import('../views/FeaturesView.vue'),
  },
  {
    path: '/rules',
    name: 'rules',
    component: () => import('../views/RulesView.vue'),
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/ContactsView.vue'),
  },
  // {
  //   path: '/news',
  //   name: 'news',
  //   component: () => import('../views/NewsView.vue'),
  // },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: function (to) {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
