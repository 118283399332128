<template>
  <div :class="classList">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    side: {
      type: String,
      required: true,
      validator(value) {
        return ['left', 'right'].includes(value);
      },
    },
  },

  computed: {
    classList() {
      let list = ['widget'];

      if (this.side) {
        list.push(`widget-${this.side}-side`);
      }

      return list;
    },
  },
};
</script>

<style lang="scss">
@use "bulma/sass/utilities/mixins";
@import "@/assets/scss/_shared";

.widget {
  padding: 10px;
  font-size: 12px;
  background: darken($bg-color, 5%);
  color: white;
  margin-bottom: 10px;

  &:first-child {
    margin-top: 10px;
  }
}

@include mixins.tablet {
  .widget:first-child {
    margin-top: 80px;
  }
}

.widget.no-padding {
  padding: 0;
}

.widget-default-side {
  @include bordered;
}

.widget-left-side {
  @include bordered;
  border-right: 0;
}
.widget-right-side {
  @include bordered;
  border-left: 0;
}

.widget-header {
  font-weight: bold;
  margin-bottom: 10px;
}
</style>