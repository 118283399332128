<template>
  <div id="app" class="app-container container">
    <div class="columns is-gapless">
      <div class="column">
        <MainMenu/>
      </div>
      <div class="column is-three-quarters">
        <main class="main">
          <div class="header">
            <a href="/" title="На главную">
              <img src="./assets/images/legacysentry.jpg" alt="">
            </a>
          </div>

          <div class="content">
            <RouterView/>
          </div>
        </main>
      </div>
      <div class="column">
        <CurrentMapWidget side="right"/>
        <PlayerBotsWidget side="right"/>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from './components/MainMenu.vue';
import CurrentMapWidget from './components/CurrentMapWidget.vue';
import PlayerBotsWidget from './components/PlayerBotsWidget.vue';

export default {
  components: {
    MainMenu,
    CurrentMapWidget,
    PlayerBotsWidget,
  },
};
</script>