<template>
  <SideWidget class="widget-bots" side="right">
    <h2 class="widget-header">Худшие игроки сервера</h2>
    <ol>
      <li v-for="bot in bots" :key="bot.nick">
        <RouterLink
            :to="{name: 'stats', query: {search: `${bot.nick}`}}"
        >
          {{ bot.nick }}
        </RouterLink>
      </li>
    </ol>
  </SideWidget>
</template>

<script>
import SideWidget from './SideWidget.vue';
import axios from 'axios';

export default {
  components: {
    SideWidget,
  },

  data() {
    return {
      bots: [],
    };
  },

  methods: {
    getBots() {
      axios.get('/api/stats/bots')
          .then(response => {
            this.bots = response.data.data;
          });
    },
  },

  mounted() {
    this.getBots();
  },
};
</script>

<style>
.widget-bots ol {
  margin-left: 15px;
}
</style>