import './assets/scss/app.scss';

import Vue from 'vue';
import App from './App.vue';
import router from './router';

import {
    ConfigProgrammatic,
    Table,
    Input,
    Tooltip,
    Button,
    Image,
    Carousel,
    Modal,
    Progress,
    Field,
    Tabs,
} from 'buefy';

Vue.use(Table);
Vue.use(Input);
Vue.use(Tooltip);
Vue.use(Button);
Vue.use(Image);
Vue.use(Carousel);
Vue.use(Modal);
Vue.use(Progress);
Vue.use(Field);
Vue.use(Tabs);

ConfigProgrammatic.setOptions({
    defaultIconPack: '',
    defaultIconPrev: 'icon-left',
    defaultIconNext: 'icon-right',
});

import moment from "moment";
window.moment = moment;

Vue.config.productionTip = false;

Vue.prototype.$env = process.env;

document.title = 'Legacy Sentry сервер CS 1.6';

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
